import {
  getSearchShareWidgetExport,
  getTopKeywordsWidgetExport,
  getVolumeShareWidgetExport,
  getTrendingKeywordsWidgetExport,
} from "src/store/widgets/widgetsApi";

export const WIDGET_EXPORT_EXCEL: Partial<
  Record<
    Widget.IdType,
    (
      dashboardDateRangeId: DashboardDateRange.Data["id"],
    ) => Promise<Widget.Export>
  >
> = {
  "widget-volume-share": getVolumeShareWidgetExport,
  "widget-search-share": getSearchShareWidgetExport,
  "widget-top-keywords": getTopKeywordsWidgetExport,
  "widget-trending-keywords": getTrendingKeywordsWidgetExport,
};

export const WIDGET_EXPORT_IMAGE: Widget.IdType[] = [
  "widget-volume-share",
  "widget-search-share",
  "widget-top-keywords",
  "widget-trending-keywords",
];

export const WIDGET_SYNC_MAX_DELAY: number = 600000;

export const WIDGET_SYNC_CURRENT_TIME_DELAY: number = 60000;
